<template>
  <div v-if="isReady">
    <div class="row align-items-center mb-4">
      <div class="col-md-8">
        <b-button
          class="btn btn-outline-primary btn-sm"
          @click="goToList"
        >
          <i class="ion ion-ios-arrow-back" />
          Back to Investor Details
        </b-button>
      </div>
      <div class="col-md-4 d-md-flex justify-content-end">
        <span class="text-muted" />
      </div>
    </div>

    <div class="row align-items-end mb-2 justify-content-between">
      <div class="col-md-12">
        <h4 class="font-weight-bold">
          Edit Key Party
        </h4>
      </div>
    </div>
    <general-information />
    <legal-signer-documents />
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import routeNames from '~/utilities/routes';
import GeneralInformation from '@/pages/broker-dealer/investors/components/details/legal-signer/general-information';
import LegalSignerDocuments from '@/pages/broker-dealer/investors/components/details/legal-signer/documents';

export default {
  name: 'BrokerDealerLegalSigner',
  metaInfo: {
    title: 'Legal Signer',
  },
  components: {
    LegalSignerDocuments,
    GeneralInformation,
  },
  props: {
    linkToList: {
      type: String,
      default: '/markets/investors', // replace with "required: true" when list-page is ready
    },
  },
  data() {
    return {
      investorId: this.$route.params.investorId,
      legalSignerId: this.$route.params.legalSignerId,
      isReady: false,
    };
  },
  computed: {
    ...mapState('brokerDealerInvestors', ['legalSignerByIdInfo']),
  },
  async created() {
    await this.getLegalSignerById({ params: { investorId: this.investorId, legalSignerId: this.legalSignerId } });
    this.isReady = true;
  },
  methods: {
    ...mapActions('brokerDealerInvestors', ['getLegalSignerById']),
    goToList() {
      this.$router.push(routeNames.brokerDealerInvestorDetails({ investorId: this.investorId }));
    },
  },

};
</script>
