<template>
  <div
    class="card mb-5"
  >
    <!--Documents header-->
    <div class="card-header">
      <div class="row align-items-center">
        <div class="col-md-12 d-flex justify-content-between">
          <h4 class="mb-0 text-primary">
            Documents
          </h4>
        </div>
      </div>
    </div>
    <div class="table-responsive">
      <b-table
        :items="legalSignerByIdInfo.documents"
        :url-params="queryParams"
        :fields="documentsFields"
        class="card-table"
      >
        <template
          slot="image"
          slot-scope="{item}"
        >
          <img
            class="ui-w-40 d-block link"
            :src="getImageIcon(item)"
            style="cursor: pointer"
            alt="Document image"
            @click="showPreviewDocModal(item)"
          >
        </template>
        <template
          slot="docType"
          slot-scope="{ item }"
        >
          {{ getDocType(item.docType) }}
        </template>
        <template
          slot="status"
          slot-scope="{ item }"
        >
          <b-badge
            :class="getStatusColor(item)"
          >
            {{ item.verificationStatus }}
          </b-badge>
        </template>
        <template
          slot="createdAt"
          slot-scope="{item}"
        >
          {{ item.createDate | dateFilter }}
        </template>
        <template
          slot="actions"
          slot-scope="{item}"
        >
          <div class="d-flex">
            <cp-button
              icon="ios-arrow-round-down"
              variant="outline-primary"
              :disabled="downloadingDocuments[item.documentId]"
              :is-loading="downloadingDocuments[item.documentId]"
              @click="downloadDocument(item)"
            >
              Download
            </cp-button>
          </div>
        </template>
      </b-table>
    </div>

    <preview-document-modal
      ref="previewDocModal"
      :document-id="documentId"
      :investor-id="queryParams.investorId"
      :file-type="fileType"
    />
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex';
import documents from './options';
import PreviewDocumentModal from './modals/preview-document-modal';
import CpButton from '@/components/common/standalone-components/cp-button';
import staticPathGeneration from '@/mixins/static-path-generation';

export default {
  name: 'LegalSignerDocuments',
  components: {
    CpButton,
    PreviewDocumentModal,
  },
  mixins: [staticPathGeneration],
  data() {
    return {
      queryParams: {
        investorId: this.$route.params.investorId,
        legalSignerId: this.$route.params.legalSignerId,
      },
      documentId: null,
      fileType: '',
      downloadingDocuments: {},
      isLoading: false,
      documentsFields: documents.fields,
      categories: documents.categories,
      confirmModalText: this.$t(
        'investorDetails.removeModal.message',
        [this.$t('investorDetails.documents.label.document')],
      ),
      statusOptions: documents.statuses,
    };
  },
  computed: {
    ...mapState('brokerDealerInvestors', ['legalSignerByIdInfo']),
    documetsCount() {
      return this.legalSignerByIdInfo.documents.length;
    },
    getImageIcon() {
      return ({ thumbnail, fileType }) => (
        fileType === 'application/pdf'
          ? this.getImagePath('fallback-pdf.jpg')
          : thumbnail || this.getImagePath('fallback-image.jpg')
      );
    },
  },
  methods: {
    ...mapActions('brokerDealerInvestors', ['getLegalSignerDocumentById']),
    ...mapMutations('global', ['CALL_ERROR_TOASTER']),
    showPreviewDocModal({ documentId, fileType }) {
      this.documentId = documentId;
      this.fileType = fileType;
      this.$refs.previewDocModal.$refs.previewDocument.show();
    },
    downloadDocument({ documentId }) {
      this.downloadingDocuments[documentId] = true;
      this.getLegalSignerDocumentById({
        documentId,
        ...this.queryParams,
      }).then(
        ({ url }) => {
          if (url) {
            // to easy download file, create downloadable link and click it
            const link = document.createElement('a');
            link.target = '_blank';
            link.download = url.split('/').pop();
            link.name = url.split('/').pop();
            link.href = url;
            link.click();
          } else {
            this.CALL_ERROR_TOASTER(this.$t('investorDetails.documents.message.downloadFailure'));
          }
        },
      ).finally(() => {
        delete this.downloadingDocuments[documentId];
      });
    },
    getStatusColor(document) {
      let statusColor = 'badge-outline-dark';
      switch (document.verificationStatus) {
        case 'pending':
          statusColor = 'badge-outline-primary';
          break;
        case 'not-verified':
          statusColor = 'badge-outline-danger';
          break;
        case 'manual-review':
          statusColor = 'badge-outline-dark';
          break;
        case 'verified':
          statusColor = 'badge-outline-success';
          break;
        default:
      }
      return `${statusColor}`;
    },
    getDocType(docType) {
      const { signerType, entityType } = this.legalSignerByIdInfo;
      if (signerType === 'entity' && entityType) {
        return this.findType(this.categories.documentsTypeEntity[entityType], docType);
      }
      return this.findType(this.categories.documentTypeIndividual, docType);
    },
    findType(types, type) {
      const find = ({ value }) => value.docType === type;
      const found = types.find(find);
      if (!found) return type;
      return found.text;
    },
  },
};
</script>
