var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"card mb-5"},[_vm._m(0),_c('div',{staticClass:"table-responsive"},[_c('b-table',{staticClass:"card-table",attrs:{"items":_vm.legalSignerByIdInfo.documents,"url-params":_vm.queryParams,"fields":_vm.documentsFields},scopedSlots:_vm._u([{key:"image",fn:function(ref){
var item = ref.item;
return [_c('img',{staticClass:"ui-w-40 d-block link",staticStyle:{"cursor":"pointer"},attrs:{"src":_vm.getImageIcon(item),"alt":"Document image"},on:{"click":function($event){return _vm.showPreviewDocModal(item)}}})]}},{key:"docType",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getDocType(item.docType))+" ")]}},{key:"status",fn:function(ref){
var item = ref.item;
return [_c('b-badge',{class:_vm.getStatusColor(item)},[_vm._v(" "+_vm._s(item.verificationStatus)+" ")])]}},{key:"createdAt",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("dateFilter")(item.createDate))+" ")]}},{key:"actions",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex"},[_c('cp-button',{attrs:{"icon":"ios-arrow-round-down","variant":"outline-primary","disabled":_vm.downloadingDocuments[item.documentId],"is-loading":_vm.downloadingDocuments[item.documentId]},on:{"click":function($event){return _vm.downloadDocument(item)}}},[_vm._v(" Download ")])],1)]}}])})],1),_c('preview-document-modal',{ref:"previewDocModal",attrs:{"document-id":_vm.documentId,"investor-id":_vm.queryParams.investorId,"file-type":_vm.fileType}})],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"card-header"},[_c('div',{staticClass:"row align-items-center"},[_c('div',{staticClass:"col-md-12 d-flex justify-content-between"},[_c('h4',{staticClass:"mb-0 text-primary"},[_vm._v(" Documents ")])])])])}]

export { render, staticRenderFns }