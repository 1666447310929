<template>
  <div
    class="card mb-5"
    :class="isLoading?'sec-saving':''"
  >
    <div class="card-header">
      <div class="row align-items-center">
        <div class="col-md-6">
          <h4 class="mb-0 text-primary">
            General information
          </h4>
        </div>
      </div>
    </div>
    <div class="card-body">
      <div class="mb-4 bg-light full-width">
        <div class="col-md-4">
          <table class="table mb-0">
            <tbody>
              <tr>
                <td>Key Party Type:</td>
                <td>
                  <strong>
                    {{ legalSignerByIdInfo.signerType }}
                  </strong>
                </td>
              </tr><!--investorType-->
            </tbody>
          </table>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6">
          <table class="table table-striped table-borderless mb-0">
            <tbody>
              <template v-if="isEntity">
                <tr>
                  <td>Name:</td>
                  <td>
                    <strong>
                      {{ legalSignerByIdInfo.legalName }}
                    </strong>
                  </td>
                </tr><!--Name-->
                <tr>
                  <td>Email:</td>
                  <td>
                    <strong>
                      {{ legalSignerByIdInfo.email }}
                    </strong>
                  </td>
                </tr><!--Email-->
                <tr>
                  <td>DBA:</td>
                  <td>
                    <strong>
                      {{ legalSignerByIdInfo.businessName }}
                    </strong>
                  </td>
                </tr><!--entityDba-->
              </template>
              <template v-if="isIndividual">
                <tr>
                  <td>First Name:</td>
                  <td>
                    <strong>
                      {{ legalSignerByIdInfo.individualName.firstName }}
                    </strong>
                  </td>
                </tr><!--firstName-->
                <tr>
                  <td>Middle Name:</td>
                  <td>
                    <strong>
                      {{ legalSignerByIdInfo.individualName.middleName }}
                    </strong>
                  </td>
                </tr><!--middleName-->
                <tr>
                  <td>Last Name:</td>
                  <td>
                    <strong>
                      {{ legalSignerByIdInfo.individualName.lastName }}
                    </strong>
                  </td>
                </tr><!--lastName-->
                <tr>
                  <td>Email:</td>
                  <td>
                    <strong>
                      {{ legalSignerByIdInfo.email }}
                    </strong>
                  </td>
                </tr><!--email-->
                <tr>
                  <td>Birthdate</td>
                  <td>
                    <strong>
                      {{ legalSignerByIdInfo.birthDate | dateFilter }}
                    </strong>
                  </td>
                </tr><!--birthdate-->
                <tr>
                  <td>Country of Birth: </td>
                  <td>
                    <strong>
                      {{ getCurrentCountryName('birthCountryCode') | nullToMinus }}
                    </strong>
                  </td>
                </tr><!--birthCountryCode-->
                <tr v-if="legalSignerByIdInfo.address.birthCountryCode === 'US'">
                  <td> State of Birth: </td>
                  <td>
                    <strong>
                      {{ generalDetailState('birthState') | nullToMinus }}
                    </strong>
                  </td>
                </tr><!--birthState-->
                <tr>
                  <td>City of Birth: </td>
                  <td>
                    <strong>
                      {{ legalSignerByIdInfo.address.birthCity | nullToMinus }}
                    </strong>
                  </td>
                </tr><!--birthCity-->
              </template>
            </tbody>
          </table>
        </div>
        <div class="col-md-6">
          <table class="table table-striped table-borderless mb-0">
            <tbody>
              <template v-if="isEntity">
                <tr>
                  <td>TaxID:</td>
                  <td>
                    <strong>
                      {{ legalSignerByIdInfo.taxId | nullToMinus }}
                    </strong>
                  </td>
                </tr><!--taxId-->
                <tr>
                  <td>Type:</td>
                  <td>
                    <strong>
                      {{ $t(`securitizeId.entityType["${legalSignerByIdInfo.entityType}"]`) || legalSignerByIdInfo.entityType }}
                    </strong>
                  </td>
                </tr><!--entityType-->
              </template>
              <template v-if="isIndividual">
                <tr>
                  <td>
                    Country:
                  </td>
                  <td>
                    <strong>
                      {{ getCurrentCountryName('countryCode') | nullToMinus }}
                    </strong>
                  </td>
                </tr><!--countryCode-->
                <tr v-if="legalSignerByIdInfo.address.countryCode === 'US'">
                  <td>
                    State:
                  </td>
                  <td>
                    <strong>
                      {{ generalDetailState('state') | nullToMinus }}
                    </strong>
                  </td>
                </tr><!--state-->
                <tr>
                  <td>City:</td>
                  <td>
                    <strong>
                      {{ legalSignerByIdInfo.address.city | nullToMinus }}
                    </strong>
                  </td>
                </tr><!--city-->
                <tr>
                  <td>Postal Code:</td>
                  <td>
                    <strong>
                      {{ legalSignerByIdInfo.address.zip | nullToMinus }}
                    </strong>
                  </td>
                </tr><!--postalCode-->
                <tr>
                  <td>Street Name:</td>
                  <td>
                    <strong>
                      {{ legalSignerByIdInfo.address.street }}
                    </strong>
                  </td>
                </tr><!--streetName-->
                <tr>
                  <td>Street Number:</td>
                  <td>
                    <strong>
                      {{ legalSignerByIdInfo.address.houseNumber }}
                    </strong>
                  </td>
                </tr><!--streetNumber-->
              </template>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { countriesList } from '~/pages/securitize-id/components/options';
import states from '@/utilities/us-states-list';

export default {
  name: 'GeneralInformation',
  data() {
    return {
      countriesList,
      states,
      isLoading: false,
      investorId: this.$route.params.investorId,
      legalSignerId: this.$route.params.legalSignerId,
    };
  },
  computed: {
    ...mapState('brokerDealerInvestors', ['legalSignerByIdInfo']),
    stateOptions() {
      return Object.keys(this.states)
        .map(val => ({ text: this.states[val], value: val }));
    },
    isIndividual() {
      return this.legalSignerByIdInfo.signerType === 'individual';
    },
    isEntity() {
      return this.legalSignerByIdInfo.signerType === 'entity';
    },
  },
  methods: {
    getCurrentCountryName(field) {
      const country = this.countriesList.find(item => item.value === this.legalSignerByIdInfo.address[field]);
      return country ? country.text : null;
    },
    generalDetailState(field) {
      const currentState = this.stateOptions.find(item => item.value === this.legalSignerByIdInfo.address[field]);
      return currentState ? currentState.text : null;
    },
  },
};
</script>
<style scoped>
    .full-width {
        margin: -24px;
    }
</style>
